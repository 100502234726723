import { useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import { Map as immuMap } from "immutable";
import { styled } from "@mui/material";

const StyledDiv = styled("div")(({ theme }) => ({
  position: "fixed",
  inset: "56px 0 112px 0",
  [`& > .react-transform-wrapper`]: {
    overflow: "visible",
  },
}));

function ImageZoomable({ src, style, onLoad, size }) {
  const _ref = useRef();
  const [imgState, setImgState] = useState(
    immuMap({
      containerWidth: 0,
      containerHeight: 0,
      imageNaturalWidth: 0,
      imageNaturalHeight: 0,
    })
  );
  const imageScale = useMemo(() => {
    const containerWidth = imgState.get("containerWidth");
    const containerHeight = imgState.get("containerHeight");
    const imageNaturalWidth = imgState.get("imageNaturalWidth");
    const imageNaturalHeight = imgState.get("imageNaturalHeight");
    if (
      containerWidth === 0 ||
      containerHeight === 0 ||
      imageNaturalWidth === 0 ||
      imageNaturalHeight === 0
    )
      return 0;
    const scale = Math[size === "cover" ? "max" : "min"](
      containerWidth / imageNaturalWidth,
      containerHeight / imageNaturalHeight
    );
    return scale;
  }, [imgState]);

  function handleLoadImg(img) {
    setImgState((v) =>
      v
        .set("imageNaturalWidth", img.naturalWidth)
        .set("imageNaturalHeight", img.naturalHeight)
    );
  }

  useEffect(() => {
    const image = new Image();
    image.onload = () => handleLoadImg(image);
    image.src = src;
  }, [src]);

  useLayoutEffect(() => {
    const _observer = new ResizeObserver((entries) => {
      for (let entry of entries) {
        if (entry.target !== _ref.current) continue;
        setImgState((v) =>
          v
            .set("containerWidth", entry.contentRect.width)
            .set("containerHeight", entry.contentRect.height)
        );
      }
    });
    _observer.observe(_ref.current);
    return () => {
      _observer.disconnect();
    };
  }, []);

  return (
    <StyledDiv ref={_ref} style={style}>
      {imageScale > 0 && (
        <TransformWrapper
          initialScale={imageScale}
          minScale={imageScale}
          maxScale={imageScale * 8}
          centerOnInit={size !== "cover"}
          // centerZoomedOut
        >
          <TransformComponent
            centerOnInit
            wrapperStyle={{
              width: "100%",
              height: "100%",
            }}
          >
            <img src={src} alt="" onLoad={onLoad} />
          </TransformComponent>
        </TransformWrapper>
      )}
    </StyledDiv>
  );
}
export default ImageZoomable;
