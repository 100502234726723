import { create } from "zustand";
import { persist } from "zustand/middleware";

export const useStyleStore = create(
  persist(
    (set) => ({
      bg_color: "#25215e",
      button_bg_color: "#ff671d",
      button_text_color: "#d6d6d6",
      text_color: "#d6d6d6",
      nav_bg_color: "#111112",
      nav_text_color: "#ffffff",
      mode: "dark",
      title: "",
      setStyles: (v) => set(v),
    }),
    { name: "styles-storage" }
  )
);
