import Joyride from "react-joyride";
import { useSystemStore } from "./Store/system";
import { useTutorialStore } from "./Store/tutorial";
import { useStyleStore } from "./Store/styles";
import { Fragment, useMemo } from "react";
import { useNavStore } from "./Store/nav";

// import { useNavigate } from "react-router-dom";
export function Tutorial() {
  const [bg_color, button_bg_color, button_text_color] = useStyleStore(
    (state) => [state.bg_color, state.button_bg_color, state.button_text_color]
  );
  const isViewedTutorial = useSystemStore((state) => state.isViewedTutorial);
  const [run, stepIndex, steps, cb] = useTutorialStore((state) => [
    state.run,
    state.stepIndex,
    state.steps,
    state.cb,
  ]);
  const map_active = useNavStore((state) => state.map_active);

  const _steps = useMemo(() => steps(map_active), [map_active, steps]);

  return isViewedTutorial ? (
    <Fragment />
  ) : (
    <Joyride
      callback={cb}
      continuous
      showSkipButton
      run={run}
      stepIndex={stepIndex}
      steps={_steps}
      showProgress
      styles={{
        options: {
          arrowColor: bg_color,
          backgroundColor: bg_color,
          primaryColor: button_bg_color,
          textColor: button_text_color,
        },
        // overlay: {
        //   WebkitBackdropFilter: "blur(5px) saturate(125%)",
        //   backdropFilter: "blur(5px) saturate(125%)",
        // },
      }}
      locale={{
        back: "이전",
        close: "닫기",
        last: "완료",
        next: "다음",
        open: "열기",
        skip: "건너뛰기",
      }}
    />
  );
}
