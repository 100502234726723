import {
  Button,
  ButtonBase,
  Checkbox,
  FormControl,
  FormControlLabel,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  buttonBaseClasses,
  styled,
  textFieldClasses,
} from "@mui/material";
import { APIURLs } from "../Urls";
import {
  Fragment,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import {
  DefaultNotification,
  NotificationBox,
} from "../Copmonents/Notification";
import { fetch } from "../Utils/fetch";
import {
  KEYSTORE_SYMBOL,
  PRIVATE_KEY_SYMBOL,
  PUB_KEY_SYMBOL,
  keypair,
} from "../App";
import { db } from "../Database";
import { useSystemStore } from "../Store/system";
import { Redirect } from "react-router-dom";
import ClientRenderer from "../Copmonents/ClientRenderer";
import CloudOffOutlinedIcon from "@mui/icons-material/CloudOffOutlined";
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
const StyledDiv = styled("div")(({ theme }) => ({
  width: "100%",
  height: "100%",
  overflow: "auto",
  display: "flex",
  flexDirection: "column",
  // justifyContent: "center",
  alignItems: "center",
  [`& > .inner`]: {
    width: 284,
    maxWidth: "100%",
    display: "flex",
    flexDirection: "column",
    [`& > .${textFieldClasses.root}`]: {
      marginTop: theme.spacing(1),
      [`&:first-of-type`]: {
        marginTop: theme.spacing(5),
      },
    },
    [`& > .${buttonBaseClasses.root}`]: {
      width: "100%",
      maxWidth: theme.spacing(40),
      minWidth: theme.spacing(15),
      marginTop: theme.spacing(3),
    },
  },
}));

// function pushLocation(data) {
//   history.push("/recents", {
//     building: data.building,
//     room: data.room,
//     building_password: data.building_password,
//     room_password: data.room_password,
//     phone: data.phone,
//     contact: data.contact,
//   });
// }

function NotiSMS({ data, onNames, onResult, onError }) {
  const [loading, setLoading] = useState(0);
  // const [open, setOpen] = useState(false);
  const [smsTimeout, setIsTimeout] = useState(false);
  const _tokenRef = useRef();
  const _buttonRef = useRef();
  const _timerRef = useRef();
  const timer = useRef();
  //https://localhost:8000/public/logo.png

  function handleClickResend() {
    initTimer();
    fetch(APIURLs.client.auth, data);
  }
  function handleSubmit(e) {
    e.preventDefault();
    e.stopPropagation();
    setLoading(1);
    const _data = Object.fromEntries(new FormData(e.target));

    _data.save_state = _data.save_state === "on";
    Object.assign(data, _data);
    fetch(APIURLs.client.password, data)
      .then(async (resp) => {
        if (data.save_state && resp.data.token) {
          data.token = resp.data.token;
          delete data["sms_token"];
          await Promise.resolve().then(async () => {
            const _data = Buffer.from(data.phone);
            const hash = await crypto.subtle.digest("SHA-256", _data);

            const ks1 = keypair[KEYSTORE_SYMBOL];
            const pub_key = keypair[PUB_KEY_SYMBOL];
            const t = await ks1.encrypt(resp.data.token, pub_key);

            await db.phone.put({ hash, t });
          });
        }

        if (resp.data.names) {
          onNames(resp.data.names);
        } else {
          onNames(false);
          onResult({
            ...resp.data,
            ...data,
            isSingle: true,
          });
        }
        setLoading(0);
      })
      .catch((e) => {
        onError(-2);
        setLoading(0);
      });
  }
  const initTimer = useCallback(() => {
    const _date = new Date();
    _date.setMinutes(5);
    _date.setSeconds(0);
    timer.current = _date;
    if (_timerRef.current?.firstChild?.textContent)
      _timerRef.current.firstChild.textContent = "05:00";
  }, []);

  const updateTimer = useCallback(() => {
    if (_timerRef.current.firstChild.textContent === "00:00") return;
    timer.current = new Date(timer.current.valueOf() - 1000);
    let temp = timer.current.toTimeString().split(" ");
    let tempsplit = temp[0].split(":");

    _timerRef.current.firstChild.textContent = `${tempsplit[1]}:${tempsplit[2]}`;
  }, []);

  useLayoutEffect(() => {
    const id = setTimeout(() => setIsTimeout(true), 15000);
    initTimer();
    const id2 = setInterval(() => {
      updateTimer();
    }, 1000);
    try {
      let ac;
      if ("OTPCredential" in window) {
        const input = _tokenRef.current;
        if (!input) return;

        ac = new AbortController();
        const form = input.closest("form");
        if (form) {
          form.addEventListener(
            "submit",
            (e) => {
              ac.abort();
            },
            { once: true }
          );
        }
        navigator.credentials
          .get({
            otp: { transport: ["sms"] },
            signal: ac.signal,
          })
          .then((otp) => {
            input.value = otp.code;
            if (form) _buttonRef.current.click();
          })
          .catch((err) => {
            console.log(err);
          });
      }
    } catch (e) {}

    return () => {
      clearTimeout(id);
      clearInterval(id2);
      // ac.abort();
    };
  }, []);

  return (
    <form onSubmit={handleSubmit}>
      <Paper>
        <Typography variant="subtitle1">계약자 확인</Typography>
        <Typography variant="caption">
          전화번호로 6자리 인증번호를 발송하였습니다.
        </Typography>
        <TextField
          inputRef={_tokenRef}
          fullWidth
          variant="filled"
          label="인증 번호 6자리"
          autoFocus
          name="sms_token"
          type="number"
          autocomplete="one-time-code"
          required
        />
        <div style={{ display: "inline-flex", justifyContent: "flex-end" }}>
          <Typography variant="caption" ref={_timerRef}>
            05:00
          </Typography>
        </div>
        <FormControlLabel
          control={<Checkbox defaultChecked />}
          label="이 휴대폰 기억하기"
          name="save_state"
        />
        {smsTimeout && (
          <div
            style={{
              display: "inline-flex",
              justifyContent: "flex-end",
            }}
          >
            <ButtonBase
              sx={{
                textDecoration: "underline",
                color: "primary.main",
              }}
              onClick={handleClickResend}
            >
              인증번호 재발송
            </ButtonBase>
          </div>
        )}
      </Paper>
      <Button
        variant="contained"
        type="submit"
        ref={_buttonRef}
        disabled={loading === 1}
      >
        인증하기
      </Button>
    </form>
  );
}

function NotiMultiNames({ data, names, onClose, onResult }) {
  function handleSubmit(e) {
    e.preventDefault();
    e.stopPropagation();

    const _data = Object.fromEntries(new FormData(e.target));
    fetch(APIURLs.client.password, { ...data, ..._data })
      .then((resp) => {
        onClose();
        onResult({
          ..._data,
          ...resp.data,
          ...data,
          isSingle: false,
        });
      })
      .catch((e) => {});
  }

  return (
    <form onSubmit={handleSubmit}>
      <Paper>
        <Typography variant="subtitle1">계약자명 선택</Typography>
        <Typography variant="caption">
          같은 전화번호로 여러 계약자가 등록되어 있습니다.
        </Typography>
        <FormControl>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue={names[0]}
            name="name"
          >
            {names.map((_name) => (
              <FormControlLabel
                value={_name}
                control={<Radio />}
                label={_name}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </Paper>
      <Button variant="contained" type="submit">
        선택
      </Button>
    </form>
  );
}
function NotiCautionInner({ onClose: handleClose }) {
  const [template, setTemplate] = useState();

  useEffect(() => {
    fetch(APIURLs.system.get_media_resources("caution.html")).then((resp) =>
      setTemplate(resp.data)
    );
  }, []);
  return (
    <NotificationBox
      in={!!template}
      id="caution-agreement"
      explicitClose={true}
    >
      <Fragment>
        <Paper>
          <Typography variant="h6" sx={{ mb: 3 }}>
            세대방문 시 유의사항
          </Typography>
          <ClientRenderer template={template} />
        </Paper>
        <Button variant="contained" onClick={handleClose}>
          확인
        </Button>
      </Fragment>
    </NotificationBox>
  );
}
function NotiCaution({ data }) {
  const [isReadCaution, setState] = useSystemStore((state) => [
    state.isReadCaution,
    state.set,
  ]);

  function handleClose() {
    setState({ isReadCaution: true });
  }
  return data ? (
    isReadCaution ? (
      <Redirect
        push
        to={{
          pathname: "/recents",
          state: {
            building: data.building,
            room: data.room,
            building_password: data.building_password,
            room_password: data.room_password,
            phone: data.phone,
            contact: data.contact,
            name: data.name,
            visit_type: data.visit_type,
            extra: data.extra,
          },
        }}
      />
    ) : (
      <NotiCautionInner onClose={handleClose} />
    )
  ) : (
    <Fragment />
  );
}

function InputsPage({ location }) {
  const isOffline = useSystemStore((state) => state.isOffline);
  const [opensms, setOpensms] = useState(!!location.state);
  const [data, setData] = useState(location.state);
  const [names, setNames] = useState(false);
  const [result, setResult] = useState(false);
  const [loading, setLoading] = useState(0);
  //https://localhost:8000/public/logo.png

  function handleResult(v) {
    setResult(v);
  }

  async function handleSubmit(e) {
    e.preventDefault();
    e.stopPropagation();

    setLoading(1);

    let _opensms = true;

    const data = Object.fromEntries(new FormData(e.target));

    e.target.elements["building"].value = data.building = data.building.trim();
    e.target.elements["room"].value = data.room = data.room.trim();
    e.target.elements["phone"].value = data.phone = data.phone
      .trim()
      .replaceAll("-", "");

    const _data = Buffer.from(data.phone);
    const hash = await crypto.subtle.digest("SHA-256", _data);
    const _th = await db.phone.get(hash);
    if (_th) {
      const ks1 = keypair[KEYSTORE_SYMBOL];
      const priv_key = keypair[PRIVATE_KEY_SYMBOL];
      const t = await ks1.decrypt(_th.t, priv_key);
      data.token = t;
      _opensms = false;
    }
    if (_opensms) {
      await fetch(APIURLs.client.auth, data)
        .then(() => {
          setData(data);
          setOpensms(_opensms);
          setLoading(0);
        })
        .catch(() => setLoading(-1));
    } else {
      await fetch(APIURLs.client.password, data)
        .then((resp) => {
          if (resp.data.names) {
            setData(data);
            handleHasNames(resp.data.names);
          } else {
            handleResult({
              ...resp.data,
              ...data,
            });
          }
          setLoading(0);
        })
        .catch(async (e) => {
          if (e?.response?.status === 401) {
            await db.phone.delete(hash);
            setLoading(-2);
            return;
          }
          setLoading(-1);
        });
    }
    // setLoading(0);
  }
  function handleHasNames(v) {
    setOpensms(false);
    setNames(v);
  }
  function handleCloseNotiNames(v) {
    setNames(false);
  }

  return (
    <Fragment>
      <StyledDiv>
        <div style={{ flex: 1 }} />
        {isOffline ? (
          <Fragment>
            <CloudOffOutlinedIcon />
            <Typography>오프라인</Typography>
          </Fragment>
        ) : (
          <Fragment>
            <Typography variant="h5" fontWeight={400}>
              세대 비밀번호 확인
            </Typography>
            <form className="inner" onSubmit={handleSubmit}>
              <TextField
                variant="outlined"
                label="동"
                name="building"
                placeholder="101"
                sx={{ mb: 1 }}
              />
              <TextField
                variant="outlined"
                label="호"
                name="room"
                placeholder="101"
                sx={{ mb: 1 }}
              />
              <TextField
                variant="outlined"
                label="계약자 본인 전화번호"
                name="phone"
                placeholder="숫자만 입력"
                sx={{ mb: 1 }}
              />
              <Button
                variant="contained"
                sx={{ textWrap: "balance" }}
                type="submit"
                disabled={loading === 1}
              >
                비밀번호 확인하기
                {/* <ListItemText primary="시작하기" secondary="동의로 간주하고" /> */}
              </Button>
            </form>
          </Fragment>
        )}
        <div style={{ flex: 1 }} />
      </StyledDiv>
      <NotificationBox
        id="sms-auth"
        in={opensms}
        explicitClose={true}
        onClose={() => {
          setOpensms(false);
        }}
      >
        <NotiSMS
          data={data}
          onNames={handleHasNames}
          onResult={handleResult}
          onError={() => setLoading(-2)}
        />
      </NotificationBox>
      <NotificationBox
        id="multi-names"
        in={!!names}
        explicitClose={true}
        onClose={() => {
          setNames(false);
        }}
      >
        <NotiMultiNames
          data={data}
          names={names}
          onClose={handleCloseNotiNames}
          onResult={handleResult}
        />
      </NotificationBox>
      <NotificationBox id="input-err" in={loading <= -1} explicitClose={true}>
        <DefaultNotification
          startIcon={<ReportProblemOutlinedIcon />}
          title={"오류"}
          body={
            loading === -1
              ? "올바르지 않은 정보입니다."
              : "인증에 실패했습니다."
          }
          buttonProps={{
            label: "확인",
            onClick: () => {
              setLoading(0);
            },
          }}
        />
      </NotificationBox>
      <NotiCaution data={result} />
    </Fragment>
  );
}

export default InputsPage;
