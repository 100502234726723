import {
  Backdrop,
  Button,
  Fade,
  IconButton,
  Paper,
  Slide,
  Stack,
  Typography,
  iconButtonClasses,
  paperClasses,
  styled,
} from "@mui/material";
import { OrderedMap as immuOrderedMap } from "immutable";
import { Fragment, useEffect, useMemo, useState } from "react";
import { create } from "zustand";
import CloseIcon from "@mui/icons-material/Close";

const StyledBackdrop = styled(Backdrop)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  [`& > div.inner`]: {
    position: "fixed",
    bottom: 0,
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: theme.spacing(2),
    maxHeight: "100%",
    overflow: "hidden",
    [`& > div.wrapper`]: {
      overflow: "hidden auto",
      display: "flex",
      flexDirection: "column",
      width: "100%",
      [`& > form`]: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        overflow: "hidden visible",
      },
      [`& > .${paperClasses.root}, & > form > .${paperClasses.root}`]: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2),
      },
    },
    [`& > div.close-wrapper`]: {
      paddingBottom: theme.spacing(1),
      display: "inline-flex",
      justifyContent: "center",
      [`& > .${iconButtonClasses.root}`]: {
        backgroundColor: theme.palette.background.default,
      },
    },
  },
}));
const useNotiStore = create((set) => ({
  components: immuOrderedMap(),
  push: (_id, _c) =>
    set((v) => {
      // console.log("PUSH", _c);
      return { components: v.components.set(_id, _c) };
    }),
  pop: () =>
    set((v) => ({
      components: v.components.delete(v.components.keySeq().last()),
    })),
  delete: (_id, _c) =>
    set((v) => ({
      components: v.components.delete(_id),
    })),
}));

export function Notification() {
  const [pushComponents, pop] = useNotiStore((state) => [
    state.components,
    state.pop,
  ]);

  const [currentComponent, setCurrentComponent] = useState(undefined);
  const [component = <Fragment />, opt = {}] = useMemo(
    () => currentComponent ?? [],
    [currentComponent]
  );

  const isInnerOpen = useMemo(() => {
    return !!currentComponent && currentComponent === pushComponents.last();
  }, [currentComponent, pushComponents]);

  function handleEndTransition() {
    setCurrentComponent(pushComponents.last());
  }

  function handleClose() {
    opt.onClose?.();
    pop();
  }

  useEffect(() => {
    setCurrentComponent((v) => {
      if (v) return v;
      return pushComponents.last();
    });
  }, [pushComponents]);

  return (
    <StyledBackdrop
      open={pushComponents.size > 0}
      mountOnEnter
      unmountOnExit
      onClick={!opt.explicitClose ? handleClose : undefined}
    >
      <div className="inner">
        {opt.explicitClose && !!opt.onClose && (
          <Fade
            in={isInnerOpen}
            mountOnEnter
            unmountOnExit
            timeout={{ enter: 800 }}
          >
            <div className="close-wrapper">
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </div>
          </Fade>
        )}
        <Slide
          direction="up"
          in={isInnerOpen}
          mountOnEnter
          unmountOnExit
          onExited={handleEndTransition}
        >
          <div className="wrapper">{component}</div>
        </Slide>
      </div>
    </StyledBackdrop>
  );
}

export function NotificationBox({ children, in: _in, id: _id, ...opts }) {
  useEffect(() => {
    if (_in) useNotiStore.getState().push(_id, [children, opts]);
    else {
      useNotiStore.getState().delete(_id);
      opts.onClose?.();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_in, children]);
  useEffect(() => {
    return () => {
      useNotiStore.getState().delete(_id);
      opts.onClose?.();
    };
  }, []);
  return <Fragment />;
}

export function DefaultNotification({
  title,
  body,
  startIcon,
  endIcon,
  buttonProps = {},
}) {
  const { label: buttonLabel, ...props } = useMemo(
    () => buttonProps,
    [buttonProps]
  );
  return (
    <Fragment>
      <Paper>
        <Stack flexDirection="row" alignItems="center">
          {startIcon}
          <Typography variant="subtitle1">{title}</Typography>
          {endIcon}
        </Stack>
        <Typography variant="caption" whiteSpace="pre">
          {body}
        </Typography>
      </Paper>
      <Button variant="contained" {...props}>
        {buttonLabel}
      </Button>
    </Fragment>
  );
}
