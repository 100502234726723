import {
  Button,
  CircularProgress,
  List,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Paper,
  Stack,
  Typography,
  listClasses,
  listItemButtonClasses,
  listSubheaderClasses,
  styled,
} from "@mui/material";
import { useEffect, useState, Fragment } from "react";
import { Link } from "react-router-dom";
import {
  DefaultNotification,
  NotificationBox,
} from "../Copmonents/Notification";
import {
  KEYSTORE_SYMBOL,
  PRIVATE_KEY_SYMBOL,
  PUB_KEY_SYMBOL,
  history,
  keypair,
} from "../App";
import { db } from "../Database";
import ClientRenderer from "../Copmonents/ClientRenderer";
import { APIURLs } from "../Urls";
import { fetch } from "../Utils/fetch";
import { useSystemStore } from "../Store/system";
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import { useTutorialStore } from "../Store/tutorial";

const StyledList = styled("div")(({ theme }) => ({
  width: "100%",
  height: "100%",
  overflow: "auto",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  [`& > .${listClasses.root}`]: {
    width: 284,
    maxWidth: "100%",
    // height: "100%",
    overflow: "visible",
    [`& > .${listSubheaderClasses.root}`]: {
      // minHeight: "30%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-end",
      alignItems: "center",
      top: 40,
      backgroundColor: theme.palette.background.default,
      // paddingBottom:theme.spacing(5)
    },
    // [`& > .${typographyClasses.h5}`]: {
    //   height: "50%",

    //   // text,
    //   // position: "sticky",
    //   // top: 56,
    // },
    [`& > .${listItemButtonClasses.root}`]: {
      borderBottom: `1px solid ${theme.palette.divider}`,
      [`&:first-of-type`]: {
        marginTop: theme.spacing(5),
      },
    },
  },
  //   display: "flex",
  //   flexDirection: "column",
  //   justifyContent: "space-between",
  //   alignItems: "center",
  //   overflow: "auto",
}));

async function getList() {
  const ks1 = keypair[KEYSTORE_SYMBOL];
  const priv_key = keypair[PRIVATE_KEY_SYMBOL];
  const _list = await db.info.toArray();
  const res = [];
  for (let { info, created_date } of _list) {
    let _info = JSON.parse(
      Buffer.from(await ks1.decrypt(info, priv_key), "binary").toString("utf8")
    );
    _info.created_date = created_date;
    res.push(_info);
  }
  return res;
}

function NotiPwd({ data, onClose }) {
  const [template, setTemplate] = useState();

  function handleClick() {
    onClose();
    useTutorialStore.getState().start();
  }

  useEffect(() => {
    async function digestText() {
      const ks1 = keypair[KEYSTORE_SYMBOL];
      const pub_key = keypair[PUB_KEY_SYMBOL];

      useSystemStore.getState().set({ isStaff: data.building === "0" });

      const _data = Buffer.from(`${data.building}/${data.room}`, "utf8");
      const hash = await crypto.subtle.digest("SHA-256", _data);
      const { created_date, ...__data } = data;
      const _info = await ks1.encrypt(
        Buffer.from(JSON.stringify(__data), "utf8"),
        pub_key
      );

      await db.info.put({
        hash,
        info: _info,
        created_date: new Date().toDateString(),
      });
    }

    digestText();

    fetch(APIURLs.system.get_media_resources("pwd.html")).then((resp) =>
      setTemplate(resp.data)
    );
  }, []);
  return (
    <Fragment>
      <Paper sx={{ maxHeight: "100%" }}>
        {template ? (
          <ClientRenderer template={template} data={data} />
        ) : (
          <CircularProgress />
        )}
      </Paper>
      <Button variant="contained" onClick={handleClick}>
        닫기
      </Button>
    </Fragment>
  );
}

function RecentsPage({ location }) {
  const isOffline = useSystemStore((state) => state.isOffline);
  const [contracts, setContracts] = useState([]);
  const [selectedItem, setSelectItem] = useState(location.state);
  const [err, setErr] = useState(false);

  async function handleClick(e) {
    const idx = e.currentTarget.dataset?.idx ?? undefined;
    if (idx === undefined) return;

    const data = contracts[idx];
    if (data === undefined) return;

    if (new Date(data.created_date) < new Date(new Date().toDateString())) {
      const _data = Buffer.from(data.phone);
      const hash = await crypto.subtle.digest("SHA-256", _data);
      const _th = await db.phone.get(hash);
      if (_th) {
        const ks1 = keypair[KEYSTORE_SYMBOL];
        const priv_key = keypair[PRIVATE_KEY_SYMBOL];
        const t = await ks1.decrypt(_th.t, priv_key);

        await fetch(APIURLs.client.password, {
          building: data.building,
          room: data.room,
          phone: data.phone,
          name: data.isSingle ? undefined : data.name,
          token: t,
        })
          .then((resp) => {
            if (!resp.data.names) {
              setSelectItem({
                building: data.building,
                room: data.room,
                building_password: resp.data.building_password,
                room_password: resp.data.room_password,
                phone: data.phone,
                contact: resp.data.contact,
                visit_type: resp.data.visit_type,
                name: data.name,
                extra: data.extra,
              });
            }
          })
          .catch((e) => {
            if (e?.response?.status === 401) {
              return fetch(APIURLs.client.auth, data).then(() => {
                history.push("/pwd", {
                  building: data.building,
                  room: data.room,
                  phone: data.phone,
                  name: data.name,
                });
              });
            }
            setErr(true);
          });
      } else {
        await fetch(APIURLs.client.auth, data).then(() => {
          history.push("/pwd", {
            building: data.building,
            room: data.room,
            phone: data.phone,
            name: data.name,
          });
        });
      }
    } else {
      setSelectItem({
        ...data,
      });
    }
  }

  // useEffect(() => {}, []);
  useEffect(() => {
    if (!selectedItem) getList().then((_l) => setContracts(_l));
  }, [selectedItem]);
  return (
    <Fragment>
      <StyledList>
        <div style={{ flex: 0.5 }} />
        <List disablePadding>
          <ListSubheader disableGutters>
            <Typography variant="h5" fontWeight={400}>
              최근 조회 목록
            </Typography>
          </ListSubheader>
          {contracts.length > 0 ? (
            contracts.map((i, idx) => (
              <ListItemButton
                key={`recents-item-${idx}`}
                id={`recents-item-${idx}`}
                data-idx={idx}
                onClick={handleClick}
                disableGutters
                disabled={
                  isOffline &&
                  new Date(i.created_date) < new Date(new Date().toDateString())
                }
              >
                <ListItemText
                  primary={`${i.building}동 ${i.room}호`}
                  secondary={`조회 일자 : ${new Date(
                    i.created_date
                  ).toLocaleDateString()}`}
                />
              </ListItemButton>
            ))
          ) : (
            <Stack flexDirection="column" alignItems="center">
              <Typography variant="caption" sx={{ mt: 3 }}>
                최근 조회 결과가 없습니다.
              </Typography>
              <Button
                variant="contained"
                sx={{ mt: 5 }}
                LinkComponent={Link}
                to="/pwd"
                disabled={isOffline}
              >
                세대 비밀번호 조회
              </Button>
            </Stack>
          )}
        </List>
      </StyledList>
      <NotificationBox id="diag-pwd" in={!!selectedItem} explicitClose={true}>
        <NotiPwd
          data={selectedItem}
          onClose={() => {
            setSelectItem();
          }}
        />
      </NotificationBox>
      <NotificationBox id="input-err" in={err} explicitClose={true}>
        <DefaultNotification
          startIcon={<ReportProblemOutlinedIcon />}
          title={"오류"}
          body={`인증에 실패했습니다.\n세대 비밀번호를 다시 발급하여주세요.`}
          buttonProps={{
            label: "확인",
            onClick: () => {
              setErr(false);
            },
          }}
        />
      </NotificationBox>
    </Fragment>
  );
}

export default RecentsPage;
