import React, { useEffect, useMemo, useState } from "react";
import {
  Checkbox,
  Divider,
  ListItem,
  ListItemButton as MuiListItemButton,
  ListItemIcon,
  styled,
  Typography,
  ButtonBase,
} from "@mui/material";
import Handlebars from "handlebars";
import { Parser, ProcessNodeDefinitions } from "html-to-react";
import { camelCase } from "lodash";
export const addCheckBox = React.createRef();

function convertCamelCase(node) {
  if (node.attribs)
    node.attribs = Object.fromEntries(
      Object.entries(node.attribs).map(([k, v]) => [camelCase(k), v])
    );
}

function ListItemButton({ children, idx }) {
  const [isChecked, setChecked] = useState(false);

  function handleOnClick() {
    setChecked((v) => {
      addCheckBox.current?.(idx, !v);
      return !v;
    });
  }

  useEffect(() => {
    addCheckBox.current?.(idx, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MuiListItemButton onClick={handleOnClick}>
      <ListItemIcon>
        <Checkbox checked={isChecked} />
      </ListItemIcon>
      <Typography variant="body1" whiteSpace="normal">
        {children}
      </Typography>
    </MuiListItemButton>
  );
}
const htmlParser = new Parser();
const ProcessNodeDef = new ProcessNodeDefinitions(React);
const typeTag = [
  "h1",
  "h2",
  "h3",
  "h4",
  "h5",
  "h6",
  "caption",
  "overline",
  "body1",
  "body2",
];
const preprocessingInstructions = [
  {
    // Anything else
    shouldPreprocessNode: function (node) {
      return true;
    },
    preprocessNode: convertCamelCase,
  },
];
const processingInstructions = [
  {
    // replaceChildren: true,
    shouldProcessNode: function (node) {
      return node.name && typeTag.includes(node.name);
    },
    processNode: function (node, children, index) {
      return (
        <Typography variant={node.name} {...node.attribs}>
          {node.data ?? children}
        </Typography>
      );
      //   return children;
    },
  },
  {
    // replaceChildren: true,
    shouldProcessNode: function (node) {
      return node.name && node.name === "divider";
    },
    processNode: function (node, children, index) {
      return <Divider flexItem {...node.attribs} />;
      //   return children;
    },
  },
  {
    // replaceChildren: true,
    shouldProcessNode: function (node) {
      return node.name && node.name === "checkbox";
    },
    processNode: function (node, children, index) {
      return (
        <ListItemButton
          style={{ marginBottom: 16 }}
          {...node.attribs}
          key={`listitem-${index}`}
          idx={index}
        >
          {children}
        </ListItemButton>
      );
    },
  },
  {
    // replaceChildren: true,
    shouldProcessNode: function (node) {
      return node.name && node.name === "nocheckbox";
    },
    processNode: function (node, children, index) {
      return (
        <ListItem
          style={{ marginBottom: 16 }}
          {...node.attribs}
          key={`listitem-${index}`}
          idx={index}
          disablePadding
        >
          <Typography variant="body1" whiteSpace="normal">
            {children}
          </Typography>
        </ListItem>
      );
    },
  },
  {
    // replaceChildren: true,
    shouldProcessNode: function (node) {
      return node.name && node.name === "go";
    },
    processNode: function (node, children, index) {
      return (
        <ButtonBase component="a" {...node.attribs}>
          {children}
        </ButtonBase>
      );
    },
  },
  {
    // Anything else
    shouldProcessNode: function (node) {
      return true;
    },
    processNode: ProcessNodeDef.processDefaultNode,
  },
];

// Handlebars.registerHelper("group", function (context, options) {
//   console.log(context)
//   console.log(options)
//   return `<div classname="center">` + options.fn(this) + "</div>";
// });

const PreviewArea = styled("div")(({ theme }) => ({
  // whiteSpace: "pre",
  [`& > div.center`]: {
    textAlign: "center",
    // [theme.breakpoints.up("sm")]: { fontSize: "1.7rem" },
  },
}));

function ClientRenderer({ template, data }) {
  const [rendered, setRendered] = useState();
  const readyTemplate = useMemo(() => Handlebars.compile(template), [template]);

  useEffect(() => {
    let component = "";
    try {
      component = htmlParser.parseWithInstructions(
        readyTemplate(data ?? {}),
        function () {
          return true;
        },
        processingInstructions,
        preprocessingInstructions
      );
    } catch (e) {
      console.error(e);
      component = "error";
    }
    setRendered(component);
  }, [readyTemplate, data]);

  return <PreviewArea>{rendered}</PreviewArea>;
}

export default ClientRenderer;
