import { Typography, buttonBaseClasses, styled } from "@mui/material";
import { APIURLs } from "../Urls";
// import { useSystemStore } from "../Store/system";
// import { useMemo } from "react";

const StyledLogoWrapper = styled("div")(({ theme }) => ({
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
  overflow: "auto",
  [`& > div.inner`]: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "cetner",
    alignItems: "center",
    [`& > div.logo`]: {
      backgroundRepeat: "no-repeat",
      backgroundSize: "contain",
      backgroundPosition: "center",
      backgroundImage: `url('${
        APIURLs.system.get_media_resources("logo").url
      }')`,
      width: "100%",
      height: 60,
      marginBottom: 16,
    },
    [`& > .${buttonBaseClasses.root}`]: {
      width: "50%",
      maxWidth: theme.spacing(40),
      minWidth: theme.spacing(15),
      marginTop: theme.spacing(15),
    },
  },
}));

// function displayTime(t = 0) {
//   const _t = `${t}`;
//   return _t.length === 2 ? _t : `0${_t}`;
// }

function NotAccess() {
  // const opTime = useSystemStore((state) => state.operationTime);
  // const [st_h = "00", st_m = "00", fin_h = "00", fin_m = "00"] = useMemo(() => {
  //   return (opTime ?? [0, 0, 0, 0]).map((i) => displayTime(i));
  // }, [opTime]);
  return (
    <StyledLogoWrapper>
      <div />
      <div className="inner">
        <div className="logo" />
        <Typography>행사 시간이 아닙니다</Typography>
        {/* <Typography>{`행사 시간 : ${st_h}:${st_m} - ${fin_h}:${fin_m}`}</Typography> */}
      </div>
      <div />
    </StyledLogoWrapper>
  );
}

export default NotAccess;
