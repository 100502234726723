import { create } from "zustand";
import { persist } from "zustand/middleware";
import { shallow } from "zustand/shallow";

export const useSystemStore = create(
  persist(
    (set) => ({
      isAgreedInfo: false,
      isReadCaution: false,
      isOffline: false,
      operationTime: undefined,
      isViewedTutorial: false,
      isStaff: false,
      set: (v) => set(v),
    }),
    { name: "system-storage" }
  ),
  { equalityFn: shallow }
);
