import axios from "axios";

const url_prefix =
  process.env.NODE_ENV === "production"
    ? "/api/client"
    : "https://localhost:8000/api/client";
export const pure_prefix =
  process.env.NODE_ENV === "production" ? "" : "https://localhost:8000";
// const url_prefix = "/api/staff";

function _obj2queries(dataset) {
  return dataset
    ? Object.entries(dataset).reduce(
        (queries, [k, v], i) =>
          v ?? false
            ? queries !== ""
              ? `${queries}&${k}=${v}`
              : `?${k}=${v}`
            : queries,
        ""
      )
    : "";
}

export const APIURLs = {
  system: {
    chk_ready: {
      url: `${url_prefix}/chk_ready/`,
      method: "get",
    },
    get_media_resources: (filename, noCache = false) => ({
      url: `${pure_prefix}/public/${filename}`,
      method: "get",
      headers: noCache ? { "Cache-Control": "no-cache" } : {},
    }),
  },
  client: {
    auth: {
      url: `${url_prefix}/auth/`,
      method: "post",
    },
    password: {
      url: `${url_prefix}/pwd/`,
      method: "post",
    },
  },
};
