import {
  Backdrop,
  CircularProgress,
  CssBaseline,
  ThemeProvider,
  bottomNavigationActionClasses,
  createTheme,
  styled,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";

import { APIURLs } from "./Urls";
import { fetch } from "./Utils/fetch";
import StartPage from "./Pages/Start";
import InputsPage from "./Pages/Inputs";
import Navigation from "./Copmonents/Navigation";
import { useStyleStore } from "./Store/styles";
import RecentsPage from "./Pages/Recents";
import { PrivateRoute } from "./Copmonents/Private";
import Header from "./Copmonents/Header";
import { Notification } from "./Copmonents/Notification";
import keystore from "@zkorum/keystore-idb";
import GuideMapPage from "./Pages/GuideMap";
import GuideAppPage from "./Pages/GuideApp";
import { useSystemStore } from "./Store/system";
import NotAccess from "./Pages/NotAccess";
import { Tutorial } from "./Tutorial";
import Dexie from "dexie";
import { useNavStore } from "./Store/nav";

export const history = {};
export const KEYSTORE_SYMBOL = Symbol();
export const PUB_KEY_SYMBOL = Symbol();
export const PRIVATE_KEY_SYMBOL = Symbol();
export const keypair = {
  PUB_KEY_SYMBOL: undefined,
  PRIVATE_KEY_SYMBOL: undefined,
  KEYSTORE_SYMBOL: undefined,
};

const StyledDiv = styled("div")(({ theme }) => ({
  width: "100%",
  height: "100%",
  overflow: "hidden",
  [`& > div`]: {
    padding: theme.spacing(2),
    [`&::before`]: {
      content: '""',
      height: theme.spacing(5),
      width: "100%",
      flexShrink: 0,
      display: "block",
    },
    [`&::after`]: {
      content: '""',
      height: theme.spacing(9),
      width: "100%",
      flexShrink: 0,
      display: "block",
    },
  },
}));

function App() {
  const [isReady, setReady] = useState(0);
  const [
    bg_color,
    button_bg_color,
    button_text_color,
    text_color,
    mode,
    setStyledData,
  ] = useStyleStore((state) => [
    state.bg_color,
    state.button_bg_color,
    state.button_text_color,
    state.text_color,
    state.mode,
    state.setStyles,
  ]);
  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: mode ?? "dark",
          primary: {
            main: button_bg_color ?? "#fff",
            paper: bg_color ?? "#fff",
          },
          background: {
            default: bg_color ?? undefined,
            paper: bg_color ?? undefined,
          },
        },
        typography: {
          fontFamily: '"Noto Sans KR","Roboto","Helvetica","Arial",sans-serif;',
        },
        components: {
          MuiTypography: {
            styleOverrides: {
              root: {
                color: text_color ?? "#000",
              },
            },
          },
          MuiButton: {
            styleOverrides: {
              root: {
                borderRadius: 100,
                color: button_text_color ?? "#fff",
              },
            },
          },
          MuiPaper: {
            styleOverrides: {
              root: {
                borderRadius: 14,
              },
            },
          },
          MuiBackdrop: {
            styleOverrides: {
              root: {
                "@supports (backdrop-filter: blur(5px)) or (-webkit-backdrop-filter: blur(5px))":
                  {
                    WebkitBackdropFilter: "blur(5px) saturate(125%)",
                    backdropFilter: "blur(5px) saturate(125%)",
                  },
              },
            },
          },
        },
      }),
    [bg_color, button_bg_color, button_text_color, mode, text_color]
  );
  useEffect(() => {
    const useragt = navigator.userAgent.toLowerCase();
    const target_url = window.location.href;

    if (useragt.match(/kakaotalk/i)) {
      //카카오톡 외부브라우저로 호출
      window.location.href =
        "kakaotalk://web/openExternal?url=" + encodeURIComponent(target_url);
    } else {
      window.ononline = (event) => {
        useSystemStore.setState({ isOffline: false });
      };
      window.onoffline = (event) => {
        useSystemStore.setState({ isOffline: true });
      };
      fetch(APIURLs.system.get_media_resources(`web.json`))
        .then((resp) => {
          useNavStore.getState().setNav({ map_active: resp.data.map_active });
          document.title = (resp.data.title ?? "loading").trim();
          setStyledData({ title: resp.data.title ?? "loading" });
          const _oldKey = localStorage.getItem("_key_title");
          if (_oldKey !== resp.data.title) {
            localStorage.clear();
            localStorage.setItem("_key_title", resp.data.title ?? "undefined");
            return Dexie.getDatabaseNames()
              .then((names) =>
                Promise.allSettled(names.map((n) => new Dexie(n).delete()))
              )
              .finally(() =>
                caches.keys().then((keyList) =>
                  Promise.allSettled(
                    keyList.map((key) => {
                      return caches.delete(key);
                    })
                  )
                )
              );
          }
        })
        .finally(() => {
          fetch(APIURLs.system.get_media_resources(`css.json`)).then((resp) =>
            setStyledData(resp.data)
          );
          async function privateKey() {
            const config = {
              type: "rsa", // 'ecc' | 'rsa'
              curve: "P-256", // 'P-256' | 'P-384' | 'P-521'
              rsaSize: 2048, // 1024 | 2048 | 4096
              symmAlg: "RSA-OAEP", // 'AES-CTR' | 'AES-GCM' | 'AES-CBC'
              symmLen: 128, // 128 | 192 | 256
              hashAlg: "SHA-1", // 'SHA-1' | 'SHA-256' | 'SHA-384' | 'SHA-512'
              charSize: 8, // 8 | 16
              storeName: "keystore", // any string
              exchangeKeyName: keystore.DEFAULT_EXCHANGE_KEY_NAME,
              writeKeyName: keystore.DEFAULT_WRITE_KEY_NAME,
            };
            const ks1 = await keystore.init(config);
            await ks1.createIfDoesNotExist(
              keystore.DEFAULT_WRITE_KEY_NAME,
              keystore.DEFAULT_EXCHANGE_KEY_NAME
            );

            // ks1.encrypt("asd")
            keypair[KEYSTORE_SYMBOL] = ks1;
            keypair[PUB_KEY_SYMBOL] = await ks1.publicExchangeKey(
              keystore.DEFAULT_EXCHANGE_KEY_NAME
            );
            keypair[PRIVATE_KEY_SYMBOL] = keystore.DEFAULT_EXCHANGE_KEY_NAME;

            // console.log("exchangeKey1: ", exchangeKey1);
            // console.log("writeKey1: ", writeKey1);

            // let cipher = await ks1.encrypt("hellohi", exchangeKey1);
            // let decipher = await ks1.decrypt(
            //   cipher,
            //   keystore.DEFAULT_EXCHANGE_KEY_NAME
            // );
            // console.log(decipher);
          }
          function getOperationTime() {
            const opTime = useSystemStore.getState().operationTime;
            const fetched = fetch(APIURLs.system.chk_ready).then((resp) => {
              useSystemStore.setState({ operationTime: resp.data });
              return resp.data;
            });
            if (opTime) {
              return opTime;
            } else return fetched;
          }
          Promise.all([privateKey(), getOperationTime()])
            .then(([_, _optime]) => {
              if (_optime.length !== 4) return Promise.reject();
              const [st_h, st_m, fin_h, fin_m] = _optime;
              if (
                new Date().setHours(st_h, st_m, 0, 0) <= new Date().getTime() &&
                new Date().setHours(fin_h, fin_m, 0, 0) >= new Date().getTime()
              )
                setReady(2);
              else setReady(1);
            })
            .catch(() => setReady(-1));
        });
    }
  }, []);
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {/* <GlobalStyles styles={{}} /> */}
      <Router basename={process.env.PUBLIC_URL}>
        <Switch>
          <Route exact path="/" component={StartPage} />
          {isReady === 2 ? (
            <PrivateRoute path="*">
              <Header />
              <StyledDiv
                style={{
                  backgroundColor: bg_color,
                }}
              >
                <Route exact path="/recents" component={RecentsPage} />
                <Route exact path="/pwd" component={InputsPage} />
                <Route exact path="/map" component={GuideMapPage} />
                <Route exact path="/app" component={GuideAppPage} />
              </StyledDiv>
              <Navigation />
              <Notification />
            </PrivateRoute>
          ) : (
            <NotAccess />
          )}
        </Switch>
      </Router>
      <Backdrop open={isReady === 0} mountOnEnter unmountOnExit>
        <CircularProgress />
      </Backdrop>
      <Tutorial />
    </ThemeProvider>
  );
}

export default App;
