import { useLayoutEffect, useRef } from "react";

function YoutubePlayer({ src, onLoad }) {
  const _ref = useRef();
  const _iframeRef = useRef();
  useLayoutEffect(() => {
    const _observer = new ResizeObserver((entries) => {
      for (let entry of entries) {
        if (entry.target !== _ref.current) continue;

        _iframeRef.current.width = `${entry.contentRect.width}`;
        _iframeRef.current.height = `${entry.contentRect.height}`;
      }
    });
    _observer.observe(_ref.current);
    return () => {
      _observer.disconnect();
    };
  }, []);
  return (
    <div ref={_ref} style={{ width: "100%", flex: 1, overflow: "hidden" }}>
      <iframe
        ref={_iframeRef}
        title="ytplayer"
        id="ytplayer"
        type="text/html"
        width="1"
        height="1"
        src={`https://www.youtube.com/embed/${src}`}
        frameborder="0"
        onLoad={onLoad}
      />
    </div>
  );
}
export default YoutubePlayer;
