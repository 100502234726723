import {
  Slide,
  Tab,
  Tabs,
  styled,
  tabClasses,
  tabsClasses,
} from "@mui/material";
import {
  Password as PasswordIcon,
  RecentlyViewed as RecentlyViewedIcon,
  Plan as PlanIcon,
  Task as TaskIcon,
} from "@carbon/icons-react";
import { Link, useLocation } from "react-router-dom";
import { useStyleStore } from "../Store/styles";
import { useNavStore } from "../Store/nav";

const StyledBottomNav = styled("div")(({ theme }) => ({
  position: "fixed",
  bottom: theme.spacing(0),
  left: theme.spacing(0),
  right: theme.spacing(0),
  zIndex: 1,
  display: "inline-flex",
  justifyContent: "center",
  padding: theme.spacing(0, 2, 2, 2),
  [`& > .${tabsClasses.root}`]: {
    borderRadius: 14,
    boxShadow: theme.shadows[5],
    padding: "0 14px",
    // backgroundColor: "transparent",
    [`& .${tabClasses.root}`]: {
      height: theme.spacing(7),
      minWidth: theme.spacing(8),
    },
  },
}));

function Navigation() {
  const { pathname } = useLocation();
  const [map_active] = useNavStore((state) => [state.map_active]);
  const [nav_bg_color, nav_text_color] = useStyleStore((state) => [
    state.nav_bg_color,
    state.nav_text_color,
  ]);
  return (
    <Slide direction="up" in={true} timeout={{ enter: 600 }}>
      <StyledBottomNav>
        <Tabs
          value={pathname}
          variant="scrollable"
          // allowScrollButtonsMobile
          scrollButtons="auto"
          sx={{
            backgroundColor: nav_bg_color ?? "#111112",
            color: nav_text_color ?? "#ffffff",
            [`& .${tabClasses.root}:not(.${tabClasses.selected})`]: {
              color: nav_text_color ?? "#ffffff",
            },
          }}
          // value={value}
          // onChange={(event, newValue) => {
          //   setValue(newValue);
          // }}
        >
          <Tab
            // label="최근 목록"
            icon={<RecentlyViewedIcon />}
            LinkComponent={Link}
            to="/recents"
            value="/recents"
            id="recents-button"
          />
          <Tab
            // label="비밀번호"
            icon={<PasswordIcon />}
            LinkComponent={Link}
            to="/pwd"
            value="/pwd"
            id="pwd-button"
          />
          {map_active && (
            <Tab
              // label="가이드맵"
              icon={<PlanIcon />}
              LinkComponent={Link}
              to="/map"
              value="/map"
              id="guide-button"
            />
          )}
          <Tab
            // label="하자점검"
            icon={<TaskIcon />}
            LinkComponent={Link}
            to="/app"
            value="/app"
            id="app-button"
          />
          {/* <BottomNavigationAction label="Favorites" icon={<FavoriteIcon />} />
      <BottomNavigationAction label="Archive" icon={<ArchiveIcon />} /> */}
        </Tabs>
      </StyledBottomNav>
    </Slide>
  );
}

export default Navigation;
