import {
  Backdrop,
  Button,
  Divider,
  Paper,
  Slide,
  Typography,
  buttonBaseClasses,
  paperClasses,
  styled,
} from "@mui/material";
import { APIURLs } from "../Urls";
import { Fragment, useState } from "react";
import { useSystemStore } from "../Store/system";
import { useStyleStore } from "../Store/styles";

const StyledLogoWrapper = styled("div")(({ theme }) => ({
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
  overflow: "auto",
  [`& > div.inner`]: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "cetner",
    alignItems: "center",
    [`& > div.logo`]: {
      backgroundRepeat: "no-repeat",
      backgroundSize: "contain",
      backgroundPosition: "center",
      backgroundImage: `url('${
        APIURLs.system.get_media_resources("logo").url
      }')`,
      width: "100%",
      height: 60,
      marginBottom: 16,
    },
    [`& > .${buttonBaseClasses.root}`]: {
      width: "50%",
      maxWidth: theme.spacing(40),
      minWidth: theme.spacing(15),
      marginTop: theme.spacing(5),
    },
  },
}));

const StyledBackdrop = styled(Backdrop)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  [`& > div.inner`]: {
    position: "fixed",
    bottom: 0,
    width: "100%",
    display: "inline-flex",
    justifyContent: "center",
    padding: theme.spacing(2),
    [`& > div.wrapper`]: {
      overflow: "auto",
      display: "flex",
      flexDirection: "column",
      width: "100%",
      [`& > .${paperClasses.root}`]: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2),
      },
    },
  },
}));

function StartPage({ history }) {
  const title = useStyleStore((state) => state.title);
  // const [open, setOpen] = useState(false);
  //https://localhost:8000/public/logo.png
  // function handleClose() {
  //   setOpen(false);
  // }
  // function handleOpen() {
  //   setOpen(true);
  // }
  function handleClickAgree() {
    useSystemStore.getState().set({ isAgreedInfo: true });
    history.push("/pwd");
  }

  return (
    <Fragment>
      <StyledLogoWrapper>
        <div />
        <div className="inner">
          <div className="logo" />
          <Typography variant="h6">{title}</Typography>
          <Button variant="contained" onClick={handleClickAgree}>
            시작하기
            {/* <ListItemText primary="시작하기" secondary="동의로 간주하고" /> */}
          </Button>
        </div>
        <div />
      </StyledLogoWrapper>
      {/* <StyledBackdrop
        open={open}
        onClick={handleClose}
        mountOnEnter
        unmountOnExit
      >
        <div className="inner">
          <Slide direction="up" in={open} mountOnEnter unmountOnExit>
            <div className="wrapper">
              <Paper>
                <Typography variant="h6">개인정보 수집 동의</Typography>
                <Divider middle flexItem sx={{ mt: 1, mb: 1 }} />
                <Typography variant="body2">계약자 본인 확인을 위해</Typography>
                <Typography variant="body2">
                  아래와 같이 개인정보를 수집•이용합니다
                </Typography>
                <Typography variant="body2" sx={{ mt: 2 }}>
                  • 수집 목적 : 모바일 키오스크 서비스 제공
                </Typography>
                <Typography variant="body2">• 수집 항목 : 계약자 전화번호</Typography>
                <Typography variant="body2">
                  • 보유 기간 : 입주자 사전방문행사 기간 내
                </Typography>
              </Paper>
              <Button variant="contained" onClick={handleClickAgree}>
                동의
              </Button>
            </div>
          </Slide>
        </div>
      </StyledBackdrop> */}
    </Fragment>
  );
}
export default StartPage;
