import { Typography } from "@mui/material";
import { create } from "zustand";
import { shallow } from "zustand/shallow";

import { history } from "../App";
import { useSystemStore } from "./system";
import { useNavStore } from "./nav";
const steps = (map) =>
  [
    {
      target: "#recents-button",
      content: (
        <>
          <Typography variant="body1">최근 조회 목록</Typography>
          <Typography variant="body2">
            최근 조회했던 세대 목록입니다.
          </Typography>
        </>
      ),
      disableBeacon: true,
    },
    {
      target: "#recents-item-0",
      content: (
        <>
          <Typography variant="body1">비밀번호 재확인</Typography>
          <Typography variant="body2">
            조회 항목을 누르시면 비밀번호 재확인이 가능합니다.
          </Typography>
        </>
      ),
      disableBeacon: true,
    },
    {
      target: "#pwd-button",
      content: (
        <>
          <Typography variant="body1">세대 비밀번호 조회</Typography>
          <Typography variant="body2">
            공동현관 및 세대 비밀번호 조회 페이지입니다.
          </Typography>
        </>
      ),
    },
    map
      ? {
          target: "#guide-button",
          content: (
            <>
              <Typography variant="body1">단지 가이드 맵</Typography>
              <Typography variant="body2">
                단지의 편의 시설 안내 지도입니다.
              </Typography>
            </>
          ),
        }
      : false,
    {
      target: "#app-button",
      content: (
        <>
          <Typography variant="body1">하자점검 서비스 안내</Typography>
          <Typography variant="body2">
            하자점검 서비스 안내 페이지입니다.
          </Typography>
        </>
      ),
    },
    {
      target: "#go-app-button",
      content: (
        <>
          <Typography variant="body1">하자점검 서비스 바로가기</Typography>
          <Typography variant="body2">
            하자점검 서비스로 바로 이동합니다.
          </Typography>
        </>
      ),
    },
  ].filter((i) => !!i);

export const useTutorialStore = create(
  (set) => ({
    run: false,
    stepIndex: 0,
    steps,
    //   set: (v) => set(v),
    cb: (data) => {
      const { action, index, lifecycle, type } = data;
      // console.log(action, index, lifecycle, type);

      if (type === "step:after") {
        if (index === 0) {
          set({ run: true, stepIndex: 1 });
        } else if (index === 1) {
          history.replace("/pwd");
          set({
            run: true,
            stepIndex: useNavStore.getState().map_active ? 2 : 3,
          });
        } else if (index === 2) {
          history.replace("/map");
          set({ run: true, stepIndex: 3 });
        } else if (index === 3) {
          history.replace("/app");
          set({ run: true, stepIndex: 4 });
        } else if (index === 4) {
          set({ run: true, stepIndex: 5 });
        } else {
          history.replace("/recents");
          set({ run: false, stepIndex: 0 });
          useSystemStore
            .getState()
            .set({ isViewedTutorial: !useSystemStore.getState().isStaff });
        }
      } else if (type === "tour:end") {
        set({ run: false, stepIndex: 0 });
        useSystemStore
          .getState()
          .set({ isViewedTutorial: !useSystemStore.getState().isStaff });
      }
      //     set({ run: false });

      //   } else if (type === "step:after" && index === 1) {
      //     if (action === "next") {
      //       set({ run: false });
      //       history.replace("/multi-route/b");
      //     } else {
      //       set({ run: true, stepIndex: 0 });
      //       history.replace("/multi-route");
      //     }
      //   } else
    },
    start: () => {
      if (!useSystemStore.getState().isViewedTutorial) set({ run: true });
    },
  }),
  { equalityFn: shallow }
);
