import {
  Button,
  CircularProgress,
  Typography,
  buttonClasses,
  styled,
} from "@mui/material";
// import PDFRenderer from "../Copmonents/PDFRenderer";
import ImageZoomable from "../Copmonents/ImageZoomable";
import DownloadIcon from "@mui/icons-material/Download";
import { Fragment, useEffect, useState } from "react";
import { APIURLs } from "../Urls";
import YoutubePlayer from "../Copmonents/YoutubePlayer";
import CloudOffOutlinedIcon from "@mui/icons-material/CloudOffOutlined";
import { fetch } from "../Utils/fetch";
import { useSystemStore } from "../Store/system";

const StyledDiv = styled("div")(({ theme }) => ({
  width: "100%",
  height: "100%",
  overflow: "hidden",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  [`& > .${buttonClasses.root}`]: {
    position: "fixed",
    bottom: theme.spacing(10),
  },
}));

function getImageFormat(arrayBuffer) {
  let arr = new Uint8Array(arrayBuffer).subarray(0, 4);
  let header = "";

  for (let i = 0; i < arr.length; i++) {
    header += arr[i].toString(16);
  }

  switch (true) {
    case /^89504e47/.test(header):
      return "image/png";
    case /^47494638/.test(header):
      return "image/gif";
    case /^424d/.test(header):
      return "image/bmp";
    case /^ffd8ff/.test(header):
      return "image/jpeg";
    default:
      return false;
  }
}

function GuideMapPage() {
  const isOffline = useSystemStore((state) => state.isOffline);
  const [isLoaded, setLoaded] = useState(0);
  const [data, setData] = useState();
  function handleLoaded() {
    setLoaded(1);
  }
  function handleClickDownaload() {
    fetch({
      ...APIURLs.system.get_media_resources("guide"),
      responseType: "arraybuffer",
    })
      .then((resp) => {
        const type = getImageFormat(resp.data);
        if (!type) return;
        return window.URL.createObjectURL(new Blob([resp.data], { type }));
      })
      .then((url) => window.open(url));
  }
  useEffect(() => {
    fetch(APIURLs.system.get_media_resources("guide_map.json"))
      .then((resp) => {
        setData(resp.data);
      })
      .catch(() => {
        setLoaded(-1);
      });
  }, []);
  return (
    <StyledDiv>
      {!!data &&
        (data.type === "video" ? (
          <YoutubePlayer onLoad={handleLoaded} src={data.src} />
        ) : (
          <Fragment>
            <ImageZoomable
              src={APIURLs.system.get_media_resources("guide").url}
              size={data.size}
              onLoad={handleLoaded}
            />
            {data.downloadable !== "false" && !isOffline && (
              <Button
                variant="contained"
                size="small"
                endIcon={<DownloadIcon />}
                onClick={handleClickDownaload}
              >
                다운로드
              </Button>
            )}
          </Fragment>
        ))}
      {isLoaded !== 1 && (
        <div
          style={{
            position: "fixed",
            inset: 0,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {isLoaded === 0 ? (
            <CircularProgress />
          ) : (
            <Fragment>
              <CloudOffOutlinedIcon />
              <Typography>네트워크 오류</Typography>
            </Fragment>
          )}
        </div>
      )}
    </StyledDiv>
  );
}

export default GuideMapPage;
