import Axios from "axios";

export function fetch(
  {
    url,
    method = "get",
    contentType = "application/json",
    lang = "ko-kr",
    ...etcOptions
  },
  payload
) {
  const headers = {
    "Content-Type": contentType,
    "Accept-Language": lang,
  };
  return new Promise((resolve, reject) =>
    Axios(url, {
      method: method,
      headers: headers,
      data: payload,
      ...etcOptions,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (err.response?.status === 401) {
          reject(err);
          return;
        }
        reject(err);
      })
  );
}
