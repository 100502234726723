import {
  Button,
  CircularProgress,
  Typography,
  buttonClasses,
  styled,
} from "@mui/material";
// import PDFRenderer from "../Copmonents/PDFRenderer";
import ImageZoomable from "../Copmonents/ImageZoomable";
import YoutubePlayer from "../Copmonents/YoutubePlayer";
import { Fragment, useEffect, useState } from "react";
import { fetch } from "../Utils/fetch";
import { APIURLs } from "../Urls";
import CloudOffOutlinedIcon from "@mui/icons-material/CloudOffOutlined";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const StyledDiv = styled("div")(({ theme }) => ({
  width: "100%",
  height: "100%",
  overflow: "hidden",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  [`& > .${buttonClasses.root}`]: {
    position: "fixed",
    bottom: theme.spacing(10),
  },
}));

function GuideAppPage() {
  const [isLoaded, setLoaded] = useState(0);
  const [data, setData] = useState();
  function handleLoaded() {
    setLoaded(1);
  }
  useEffect(() => {
    fetch(APIURLs.system.get_media_resources("guide_app.json"))
      .then((resp) => {
        setData(resp.data);
      })
      .catch(() => {
        setLoaded(-1);
      });
  }, []);

  return (
    <StyledDiv>
      {!!data && (
        <Fragment>
          {data.type === "video" ? (
            <YoutubePlayer onLoad={handleLoaded} src={data.src} />
          ) : (
            <ImageZoomable
              src={APIURLs.system.get_media_resources("manual").url}
              size={data.size}
              onLoad={handleLoaded}
            />
          )}
          {data.href && (
            <Button
              id="go-app-button"
              variant="contained"
              size="small"
              endIcon={<ArrowForwardIosIcon />}
              component="a"
              href={data.href}
            >
              점검 시작하기
            </Button>
          )}
        </Fragment>
      )}
      {isLoaded !== 1 && (
        <div
          style={{
            position: "fixed",
            inset: 0,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {isLoaded === 0 ? (
            <CircularProgress />
          ) : (
            <Fragment>
              <CloudOffOutlinedIcon />
              <Typography>네트워크 오류</Typography>
            </Fragment>
          )}
        </div>
      )}
    </StyledDiv>
  );
}

export default GuideAppPage;
