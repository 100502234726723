import { Redirect, Route } from "react-router-dom";
import { useSystemStore } from "../Store/system";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";
import { history as _history } from "../App";

export function PrivateRoute({ children, ...rest }) {
  const isAgreedInfo = useSystemStore((state) => state.isAgreedInfo);
  const history = useHistory();

  useEffect(() => {
    Object.assign(_history, history);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAgreedInfo ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}
