import { IconButton, styled } from "@mui/material";
import { APIURLs } from "../Urls";
import LogoutIcon from "@mui/icons-material/Logout";
import { Fragment, useState } from "react";
import { DefaultNotification, NotificationBox } from "./Notification";
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
// import { history } from "../App";

const StyledDiv = styled("div")(({ theme }) => ({
  position: "fixed",
  left: theme.spacing(0),
  right: theme.spacing(0),
  top: theme.spacing(0),
  padding: theme.spacing(2, 0, 1, 0),
  zIndex: 2,
  "@supports (backdrop-filter: blur(5px)) or (-webkit-backdrop-filter: blur(5px))":
    {
      WebkitBackdropFilter: "blur(5px)",
      backdropFilter: "blur(5px)",
    },
  [`& > div.logo`]: {
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    backgroundPosition: "center",
    backgroundImage: `url('${APIURLs.system.get_media_resources("logo").url}')`,
    width: "100%",
    height: 32,
  },
}));
function Header() {
  const [open, setOpen] = useState(false);
  function handleClick() {
    indexedDB.deleteDatabase("clientDB");
    indexedDB.deleteDatabase("keystore");
    localStorage.clear();
    setOpen(false);
    window.location.assign("/");
    // history.push("/");
  }
  return (
    <Fragment>
      <StyledDiv>
        <div className="logo" />
        {/* <IconButton
          onClick={() => {
            setOpen(true);
          }}
          size="small"
          sx={{ position: "fixed", right: "8px", top: "10px" }}
        >
          <LogoutIcon />
        </IconButton> */}
      </StyledDiv>
      {/* <NotificationBox
        id="logout-err"
        in={open}
        explicitClose={false}
        onClose={() => {
          setOpen(false);
        }}
      >
        <DefaultNotification
          startIcon={<ReportProblemOutlinedIcon />}
          title={"경고"}
          body={"모든 데이터가 삭제됩니다.\n정말 진행하시겠습니까?"}
          buttonProps={{
            label: "확인",
            onClick: handleClick,
          }}
        />
      </NotificationBox> */}
    </Fragment>
  );
}

export default Header;
